<template>
    <div class="d-flex flex-column mt-2">
        <TransitionGroup id="works" class="works-list" :class="show.description?'expanded':''" name="list" tag="div"  v-if="item.collection?.records">
            <div v-for="(work, index) in pubs.slice(0,show.max_works+(show.description*(item.collection.records.length-show.max_works)))" 
                :key="work.identifier" 
                @click="$store.dispatch('get_item', {'popup_anchor': $event.target, 'item_id': work.identifier, 'item_data': work})" 
                title="Click to expand item." 
                class="work mb-2"
                :class='{
                    "clipped": show.description==0,
                }'>
                    <i class="fa fa-file-text me-2"></i>
                    <div class="d-inline" v-if="work.fields.highlights !== undefined" v-html="work.fields.highlights.title.full"></div>
                    <div class="d-inline" v-else>
                        <span v-if="work.fields.title?.length > 150">{{work.fields.title.slice(0,150)}}...</span>
                        <span v-else v-html="work.fields.title"></span>
                    </div>                                
            </div>
        </TransitionGroup>
        <div class="d-flex justify-content-between align-items-center" :class="{'resultlist-width': !in_popup}">
            <LabelBox :item="item"/>
            <div v-if="pubs?.length > show.max_works" class="expert-action-container d-inline-block me-2" role="button" @click="show.description=1-show.description">
                <span class="expert-action" role="button" v-show="!show.description">+ Show more</span>
                <span class="expert-action" role="button" v-show="show.description">- Show less</span>
            </div>
        </div>
    </div>
</template>

<script>
import LabelBox from './LabelBox.vue'

export default {
    name: 'CollectionItems',
    components: {LabelBox},
    props: ['item', 'pubs', 'orcid_details', 'scholar_details', 'in_popup'],
    data: function() {return({
        show: {
            description: 0,
            max_works: 2,
        }
    })},
    methods: {

    }
}
</script>

<style>
#works {
    overflow:visible;
    width:var(--resultlist_width);
}
.works-list {
    display:flex;
    flex-direction:column;
}
.resultlist-width {
    width:var(--resultlist_width);
}
.work {
    border-radius: 7px;
    /* background-color:white;
    box-shadow: var(--box_shadow); */
    padding: 0 .5rem;
    width:var(--resultlist_width);
    text-align:left;
    cursor:pointer;
}
.work:hover, .work:hover svg {
    background-color: var(--primary_color);
    color: white;
}
.work svg {
    color:var(--primary_color);
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all var(--slow_anim) ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(0px);
}

.list-leave-active {
  position: absolute;
}
</style>